import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import debounce from 'debounce';
import CrossIcon from '../../icons/cross';
import SortIcon from '../../icons/sort';
import FilterIcon from '../../icons/filter';
import SearchIcon from '../../icons/search';
import { CgChevronRight, CgChevronLeft } from 'react-icons/cg';
import { SearchProvider } from '../../context/SearchContext';
import { useProductSearch } from '../../hooks/SearchHook';
import { getFiltersFromQueryParams } from '../../utils/search';
import { getCurrencySymbol } from '../../utils/format-price';
import { Spinner } from '../../components/catalog/progress';
import { Filters } from '../../components/catalog/filters';

import '../../styles/reset.css';
import '../../styles/variables.css';
import '../../styles/global.css';
import { results, main, search, searchIcon, sortSelector } from './search.module.css';
import { pagination, paginationButton, progressStyle, resultsStyle } from './search.module.css';
import { filterStyle, clearSearch, searchForm, sortIcon } from './search.module.css';
import { filterButton, filterTitle, modalOpen } from './search.module.css';
import { activeFilters, filterWrap, emptyState } from './search.module.css';

// custom components
import { Layout } from '../../components/layout/CatalogSearch';
import StyledBox from '../../components/controls/StyledBox';
import ProductCard from '../../custom/elements/ProductCard';
import * as config from '../../../config/site';
// import * as serverDataDefault from '../../mocks/searchResult';

// SSR Server Side Rendering >> serverData props (dynamic) || SSR NOT WORKING ON NETLIFY & GATSBY.COM
// export async function getServerData({ query, ...rest }) {
//   // tell Gatsby to choose the SSR rendering option
//   // see: https://www.gatsbyjs.com/docs/how-to/rendering-options/using-server-side-rendering/

//   // const { getSearchResults } = require('../../utils/search');
//   // const resultData = await getSearchResults({
//   //   query,
//   //   count: config.catalog.search.resultPerPage,
//   // });

//   const resultData = searchResult.data;

//   return {
//     props: {
//       query,
//       products: resultData?.products,
//       productsMeta: resultData?.productsMeta,
//     },
//   };
// }

// Build time Page Query >> data props (static)
export const query = graphql`
  query ($catalog: String) {
    topics: allDatoCmsTopic(filter: { catalogs: { elemMatch: { id: { eq: $catalog } } } }) {
      nodes {
        id
        originalId
        alias
        title
      }
    }
    destinations: allDatoCmsDestination(
      filter: { catalogs: { elemMatch: { id: { eq: $catalog } } } }
    ) {
      nodes {
        id
        originalId
        alias
        title
      }
    }
    triptypes: allDatoCmsTriptype(filter: { catalogs: { elemMatch: { id: { eq: $catalog } } } }) {
      nodes {
        id
        originalId
        alias
        title
      }
    }
    venues: allDatoCmsVenue(filter: { catalogs: { elemMatch: { id: { eq: $catalog } } } }) {
      nodes {
        id
        originalId
        alias
        title
      }
    }
    patches: allDatoCmsPatch {
      nodes {
        id
        originalId
        title
      }
    }
    qualities: allDatoCmsQuality {
      nodes {
        id
        originalId
        title
      }
    }
    services: allDatoCmsService {
      nodes {
        id
        originalId
        title
      }
    }
    properties: allDatoCmsProperty {
      nodes {
        id
        originalId
        title
      }
    }
    siteSetting: datoCmsSiteSetting {
      title
      company
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`;

function SearchBar({ defaultTerm, setFilters }) {
  const [term, setTerm] = useState(defaultTerm);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetFilters = useCallback(
    debounce((value) => {
      setFilters((filters) => ({ ...filters, term: value }));
    }, 500),
    [setFilters]
  );

  return (
    <form onSubmit={(e) => e.preventDefault()} className={searchForm}>
      <SearchIcon aria-hidden className={searchIcon} />
      <input
        type="text"
        value={term}
        onChange={(e) => {
          setTerm(e.target.value);
          debouncedSetFilters(e.target.value);
        }}
        placeholder="Suche..."
      />
      {term ? (
        <button
          className={clearSearch}
          type="reset"
          onClick={() => {
            setTerm('');
            setFilters((filters) => ({ ...filters, term: '' }));
          }}
          aria-label="Clear search query"
        >
          <CrossIcon />
        </button>
      ) : undefined}
    </form>
  );
}

function Pagination({ previousPage, hasPreviousPage, nextPage, hasNextPage }) {
  // supports only next & previous navigation
  return (
    <nav className={pagination}>
      <button
        className={paginationButton}
        disabled={!hasPreviousPage}
        onClick={previousPage}
        aria-label="Previous page"
      >
        <CgChevronLeft />
      </button>
      <button
        className={paginationButton}
        disabled={!hasNextPage}
        onClick={nextPage}
        aria-label="Next page"
      >
        <CgChevronRight />
      </button>
    </nav>
  );
}

function SearchPage({ serverData, data, location, pageContext }) {
  // REMARKS: location in parameter list causes problems
  //          cause seems to be a buggy SSR management
  //          when called from Gatsby Link, the page works correct
  //          when called from Browser with url, the page is not rendered correctly
  //          (older releases of segments seems to be used from the cache)
  //          >> if not necessary, do NOT USE the location parameter or useLocation hook (see Search page)

  // const { query, products: serverProducts, productsMeta } = serverData;

  // WORKAROUND ============
  const query = serverData ? serverData.query : location.search;
  // const resultData = serverDataDefault.data;
  // const serverProducts = resultData.products;
  // const productsMeta = resultData.productsMeta;
  const serverProducts = [];
  const productsMeta = { count: 0 };
  // END WORKAROUND ========

  const { topics, destinations, triptypes, venues } = data;
  const { patches, qualities, services, properties } = data;
  const { catalogType, catalog } = pageContext;

  const pageParams = { title: 'Produktsuche' };

  const currencyCode = getCurrencySymbol(config.catalog.currency.code);
  // These default values come from the page query string
  const filterParams = getFiltersFromQueryParams(location.search || query);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultSortKey = config.catalog.sort.default;
  const initialFilters = useMemo(() => filterParams, []);
  const initialSortKey = useMemo(() => filterParams.sortKey || defaultSortKey, []);

  // Application state
  const [filters, setFilters] = useState(initialFilters);
  const [sortKey, setSortKey] = useState(initialSortKey);
  const [showModal, setShowModal] = useState(false); // This modal is only used on mobile

  // We clear the hash when searching, we want to make sure the next page will be fetched due the #more hash.
  const morePagesLoaded = useRef(false);

  // Get nodes from segments
  const topicNodes = topics.nodes;
  const destinationNodes = destinations.nodes;
  const triptypeNodes = triptypes.nodes;
  const venueNodes = venues.nodes;
  const patchNodes = patches.nodes;
  const qualityNodes = qualities.nodes;
  const serviceNodes = services.nodes;
  const propertyNodes = properties.nodes;

  // Reference datasets
  const datasets = {
    allTopics: topicNodes,
    allDestinations: destinationNodes,
    allTriptypes: triptypeNodes,
    allVenues: venueNodes,
    allPatches: patchNodes,
    allQualities: qualityNodes,
    allServices: serviceNodes,
    allProperties: propertyNodes,
  };

  // Run product search
  const searchResult = useProductSearch(filters, sortKey, initialSortKey, datasets);
  const { products, isFetching, filterCount, hasNextPage, hasPreviousPage } = searchResult;
  const { fetchNextPage, fetchPreviousPage } = searchResult;

  // Scroll up when navigating
  useEffect(() => {
    if (!showModal) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
        // eslint-disable-next-line react-hooks/exhaustive-deps
      });
    }
  }, [products, showModal]);

  // Stop page from scrolling when modal is visible
  useEffect(() => {
    if (showModal) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }
  }, [showModal]);

  // Automatically load the next page if "#more" is in the URL
  // >> NOT USED
  // useEffect(() => {
  //   // console.log(`=== Search useEffect ===`);
  //   // console.log(`Location Hash: ${location.hash}`);
  //   // console.log(`More Pages Loaded: ${morePagesLoaded.current}`);
  //   // console.log(`Has Next Page: ${hasNextPage}`);

  //   if (location.hash === '#more' && !morePagesLoaded.current) {
  //     if (hasNextPage) {
  //       // console.log(`Fetching Next Page`);
  //       morePagesLoaded.current = true;
  //       fetchNextPage();
  //     }
  //   }
  // }, [location.hash, hasNextPage, fetchNextPage]);

  // Rendering
  return (
    <Layout catalogType={catalogType} catalogId={catalog} pageParams={pageParams}>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 7 },
          mt: -20,
          mb: 1,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <StyledBox pb={{ xs: 3, md: 6 }}>
          <div className={main}>
            <div className={search} aria-hidden={modalOpen}>
              <SearchBar defaultTerm={filters.term} setFilters={setFilters} />
              <button
                className={[filterButton, filterCount ? activeFilters : undefined].join(' ')}
                onClick={() => setShowModal((show) => !show)}
                // This is hidden because the filters are already visible to
                // screenreaders, so the modal isnt needed.
                aria-hidden
              >
                <FilterIcon />
              </button>
              <div className={sortSelector}>
                <label>
                  <span>Sortierung:</span>
                  <select
                    value={sortKey}
                    // eslint-disable-next-line
                    onChange={(e) => setSortKey(e.target.value)}
                  >
                    <option value="title_ASC">Bezeichnung</option>
                    <option value="featured_DESC">Empfehlung</option>
                    <option value="weight_DESC">Relevanz</option>
                    <option value="price_ASC">Preis</option>
                    <option value="createdAt_DESC">Neue Produkte</option>
                  </select>
                </label>
                <SortIcon className={sortIcon} />
              </div>
            </div>
            <section className={[filterStyle, showModal && modalOpen].join(' ')}>
              <div className={filterTitle}>
                <h2>Filter</h2>
                <button aria-hidden onClick={() => setShowModal(false)}>
                  <CrossIcon />
                </button>
              </div>
              <div className={filterWrap}>
                <Filters
                  filters={filters}
                  currencyCode={currencyCode}
                  topics={topicNodes}
                  destinations={destinationNodes}
                  triptypes={triptypeNodes}
                  venues={venueNodes}
                  patches={patchNodes}
                  qualities={qualityNodes}
                  services={serviceNodes}
                  properties={propertyNodes}
                  setFilters={setFilters}
                />
              </div>
            </section>
            <section className={results} aria-busy={isFetching} aria-hidden={modalOpen}>
              {isFetching ? (
                <p className={progressStyle}>
                  <Spinner aria-valuetext="Searching" /> Suche
                  {filters.term ? ` nach "${filters.term}"…` : `…`}
                </p>
              ) : (
                <>
                  <p className={resultsStyle}>
                    {filters.term && (
                      <span>
                        Suchergebnisse für "<span>{filters.term}</span>"
                      </span>
                    )}
                  </p>
                </>
              )}
              {!isFetching && (
                <StyledBox mr={4}>
                  <Grid container spacing={3}>
                    {products.map((product) => (
                      <Grid item xs={12} md={6} xl={4} key={product.id}>
                        <StyledBox>
                          <ProductCard product={product} catalogType={catalogType} fromApi={true} />
                        </StyledBox>
                      </Grid>
                    ))}
                  </Grid>
                </StyledBox>
              )}
              {!isFetching && products.length === 0 && (
                <div className={emptyState}>Keine Produkte gefunden</div>
              )}
              {hasPreviousPage || hasNextPage ? (
                <Pagination
                  previousPage={fetchPreviousPage}
                  hasPreviousPage={hasPreviousPage}
                  nextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                />
              ) : undefined}
            </section>
          </div>
        </StyledBox>
      </Card>
    </Layout>
  );
}

// page component
export default function SearchPageTemplate(props) {
  const { data } = props;
  const { siteSetting, site } = data;

  return (
    <SearchProvider>
      <HelmetDatoCms seo={siteSetting.seo} favicon={site.favicon} />
      <SearchPage {...props} />
    </SearchProvider>
  );
}
