import * as React from 'react';
import { filter, summary, filterOptions } from './check-filter.module.css';
import { clearButton, selectedLabel, checkbox } from './check-filter.module.css';

export function CheckFilter({ name, items, selectedItems = [], open = true, setSelectedItems }) {
  // event handler
  const handleToggleItem = ({ currentTarget: input }) => {
    if (input.checked) {
      setSelectedItems([...selectedItems, input.value]);
    } else {
      const idx = selectedItems.indexOf(input.value);
      if (idx === -1) {
        return;
      }
      const newItems = [...selectedItems.slice(0, idx), ...selectedItems.slice(idx + 1)];
      setSelectedItems(newItems);
    }
  };

  const handleClearItems = () => {
    setSelectedItems([]);
  };

  // rendering
  return (
    <details open={open} className={filter}>
      {name && (
        <summary>
          <div className={summary}>
            {name}{' '}
            {selectedItems.length ? (
              <button className={clearButton} onClick={handleClearItems}>
                Clear
              </button>
            ) : undefined}
          </div>
        </summary>
      )}
      <div className={filterOptions}>
        {items.map((item) => (
          <label
            className={selectedItems.includes(item.originalId) ? selectedLabel : undefined}
            key={item.originalId}
          >
            <input
              type="checkbox"
              className={checkbox}
              onChange={handleToggleItem}
              value={item.originalId}
              checked={selectedItems.includes(item.originalId)}
            />{' '}
            {item.alias || item.title || 'Ohne Bezeichnung'}
          </label>
        ))}
      </div>
    </details>
  );
}
