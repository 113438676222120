import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useQuery } from 'urql';
import { ProductsQuery, arrayify } from '../utils/search';
import * as config from '../../config/site';

function makeQueryStringValue(allItems, selectedItems) {
  if (allItems.length === selectedItems.length) {
    return [];
  }
  return selectedItems;
}

export function useProductSearch(filters, sortKey, initialSortKey, datasets) {
  const { allTopics, allDestinations, allTriptypes, allVenues } = datasets;
  const { allPatches, allQualities, allServices, allProperties } = datasets;
  const initialCursor = { count: 0, first: config.catalog.search.resultPerPage, skip: 0 };

  const [cursors, setCursors] = useState(initialCursor);

  const orderBy = sortKey || initialSortKey;
  const orderByArray = arrayify(orderBy);

  // console.log('=== useProductSearch ===');
  // console.log(`sortKey: ${sortKey}`);
  // console.log(`initialSortKey: ${initialSortKey}`);
  // console.log(filters);
  // console.log(cursors);

  const [result] = useQuery({
    query: ProductsQuery,
    variables: {
      order: orderByArray,
      first: cursors.first,
      skip: cursors.skip,
      // Price range
      minPrice: filters.minPrice || undefined,
      maxPrice: filters.maxPrice || undefined,
      // Query params
      term: filters.term || '',
      catalog: filters.catalog,
      topics: filters.topics,
      destinations: filters.destinations,
      triptypes: filters.triptypes,
      venues: filters.venues,
      patches: filters.patches,
      qualities: filters.qualities,
      services: filters.services,
      properties: filters.properties,
    },
    pause: false,
  });

  // console.log('=== Query Result ===');
  // console.log(result);

  useEffect(() => {
    const metaData = result.data && result.data.productsMeta ? result.data.productsMeta : {};
    const productsTotal = metaData && metaData.count ? metaData.count : 0;
    const setcount = cursors.first;
    const start = cursors.skip;
    setCursors({
      count: productsTotal,
      first: setcount,
      skip: start,
    });
  }, [result.data]);

  useEffect(() => {
    const qs = queryString.stringify({
      c: filters.catalog,

      // Don't show if falsy
      s: filters.term || undefined,
      a: filters.maxPrice || undefined,
      i: filters.minPrice || undefined,

      // Don't show if sort order is default
      o: sortKey === initialSortKey ? undefined : sortKey,

      // Don't show if all values are selected
      t: makeQueryStringValue(allTopics, filters.topics),
      d: makeQueryStringValue(allDestinations, filters.destinations),
      u: makeQueryStringValue(allTriptypes, filters.triptypes),
      v: makeQueryStringValue(allVenues, filters.venues),
      p: makeQueryStringValue(allPatches, filters.patches),
      k: makeQueryStringValue(allQualities, filters.qualities),
      g: makeQueryStringValue(allServices, filters.services),
      m: makeQueryStringValue(allProperties, filters.properties),

      // Don't show if no cursor
      x: cursors.count || undefined,
      y: cursors.first || undefined,
      z: cursors.skip || undefined,
    });

    const url = new URL(window.location.href);
    url.search = qs;
    url.hash = '';
    window.history.replaceState({}, null, url.toString());
    // setQuery(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, cursors, sortKey]);

  const fetchPreviousPage = () => {
    const total = cursors.count;
    const setcount = cursors.first;
    const start = cursors.skip;
    const newStart = start - setcount >= 0 ? start - setcount : 0;
    setCursors({
      count: total,
      first: setcount,
      skip: newStart,
    });
  };
  const fetchNextPage = () => {
    const total = cursors.count;
    const setcount = cursors.first;
    const start = cursors.skip;
    const newStart = start + setcount <= total ? start + setcount : start;
    setCursors({
      count: total,
      first: setcount,
      skip: newStart,
    });
  };

  const filterCount =
    (filters.topics.length === allTopics.length ? 0 : filters.topics.length) +
    (filters.destinations.length === allDestinations.length ? 0 : filters.destinations.length) +
    (filters.triptypes.length === allTriptypes.length ? 0 : filters.triptypes.length) +
    (filters.venues.length === allVenues.length ? 0 : filters.venues.length) +
    (filters.patches.length === allPatches.length ? 0 : filters.patches.length) +
    (filters.qualities.length === allQualities.length ? 0 : filters.qualities.length) +
    (filters.services.length === allServices.length ? 0 : filters.services.length) +
    (filters.properties.length === allProperties.length ? 0 : filters.properties.length) +
    (filters.minPrice ? 1 : 0) +
    (filters.maxPrice ? 1 : 0);

  let hasPreviousPage;
  let hasNextPage;
  if (result && result.data) {
    hasPreviousPage = cursors.skip > 0;
    hasNextPage = cursors.skip + cursors.first < cursors.count;
  }

  const products = result.data?.products || [];
  const isFetching = result.fetching;

  return {
    data: result.data,
    products,
    isFetching,
    filterCount,
    hasPreviousPage,
    hasNextPage,
    fetchNextPage,
    fetchPreviousPage,
  };
}
