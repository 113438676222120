import queryString from 'query-string';
import { searchClient } from '../context/SearchContext';
import * as config from '../../config/site';

export const ProductsQuery = `
query ($term: String!, $catalog: [ItemId]!, $minPrice: FloatType, $maxPrice: FloatType, $order: [ItemModelOrderBy], $first: IntType, $skip: IntType, $topics: [ItemId], $destinations: [ItemId], $triptypes: [ItemId], $venues: [ItemId], $patches: [ItemId], $qualities: [ItemId], $services: [ItemId], $properties: [ItemId]) {
  productsMeta: _allItemsMeta(filter: {title: {matches: {pattern: $term}}, price: {gte: $minPrice, lte: $maxPrice}, catalogs: {anyIn: $catalog}, topics: {anyIn: $topics}, destinations: {anyIn: $destinations}, triptypes: {anyIn: $triptypes}, venues: {anyIn: $venues}, patches: {anyIn: $patches}, qualities: {anyIn: $qualities}, services: {anyIn: $services}, properties: {anyIn: $properties}}) {
    count
  }
  products: allItems(filter: {title: {matches: {pattern: $term}}, price: {gte: $minPrice, lte: $maxPrice}, catalogs: {anyIn: $catalog}, topics: {anyIn: $topics}, destinations: {anyIn: $destinations}, triptypes: {anyIn: $triptypes}, venues: {anyIn: $venues}, patches: {anyIn: $patches}, qualities: {anyIn: $qualities}, services: {anyIn: $services}, properties: {anyIn: $properties}}, first: $first, skip: $skip, orderBy: $order) {
    id
    producttype
    title
    coverHeader
    booking
    featured
    weight
    teaser
    price
    ismin
    priceAlias
    image {
      responsiveImage(imgixParams: {fit: crop, w: 450, h: 300, auto: format}) {
        ...responsiveImageFragment
      }
      basename
    }
    patches {
      id
      title
      icon
      color
      description
    }
  }
}

fragment responsiveImageFragment on ResponsiveImage {
  title
  alt
  aspectRatio
  width
  height
  sizes
  src
  srcSet
  webpSrcSet
  base64
}
`;

export function arrayify(value) {
  if (!value) {
    return [];
  }
  if (!Array.isArray(value)) {
    return [value];
  }
  return value;
}

export function getOriginalKeyArrayFromNodes(nodes) {
  let keyArray = [];
  let nodesArray = arrayify(nodes);
  if (nodesArray && nodesArray.length > 0) {
    nodesArray.forEach((node) => {
      keyArray.push(node.originalId);
    });
  }
  return keyArray;
}

export function getFiltersFromQueryParams(query) {
  const isClient = typeof query === 'string';
  const queryParams = isClient ? queryString.parse(query) : query;
  const {
    c: catalog,
    s: term,
    a: maxPrice,
    i: minPrice,
    o: sortKey,
    t: topics,
    d: destinations,
    u: triptypes,
    v: venues,
    p: patches,
    k: qualities,
    g: services,
    m: properties,
  } = queryParams;

  const queryObject = {
    catalog: arrayify(catalog),
    term,
    maxPrice,
    minPrice,
    sortKey,
    topics: arrayify(topics),
    destinations: arrayify(destinations),
    triptypes: arrayify(triptypes),
    venues: arrayify(venues),
    patches: arrayify(patches),
    qualities: arrayify(qualities),
    services: arrayify(services),
    properties: arrayify(properties),
  };
  return queryObject;
}

export async function getSearchResults({ query, count = 24 }) {
  const filters = getFiltersFromQueryParams(query);
  const defaultSortKey = config.catalog.sort.default;
  const orderBy = filters.sortKey || defaultSortKey;
  const orderByArray = arrayify(orderBy);

  // console.log('=== getSearchResults ===');
  // console.log(filters);
  // console.log(`sortKey: ${filters.sortKey}`);
  // console.log(`initialSortKey: ${initialSortKey}`);
  // console.log(`orderByArray: ${orderByArray}`);

  const results = await searchClient
    .query(ProductsQuery, {
      order: orderByArray,
      first: count,
      skip: 0,
      // Price range
      minPrice: filters.minPrice || undefined,
      maxPrice: filters.maxPrice || undefined,
      // Query params
      term: filters.term || '',
      catalog: filters.catalog,
      topics: filters.topics,
      destinations: filters.destinations,
      triptypes: filters.triptypes,
      venues: filters.venues,
      patches: filters.patches,
      qualities: filters.qualities,
      services: filters.services,
      properties: filters.properties,
    })
    .toPromise();

  // console.log('=== Query Result ===');
  // console.log(results);

  return results.data;
}
