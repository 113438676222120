import { useState, useCallback, Fragment } from 'react';
import debounce from 'debounce';
import { CheckFilter } from './check-filter';
import { CurrencyField } from './currency-field';
import { priceFilterStyle, clearButton, priceFields, summary } from './filters.module.css';
import * as config from '../../../config/site';

export function Filters({
  filters,
  currencyCode,
  topics,
  destinations,
  triptypes,
  venues,
  patches,
  qualities,
  services,
  properties,
  setFilters,
}) {
  const [minPrice, setMinPrice] = useState(filters.minPrice);
  const [maxPrice, setMaxPrice] = useState(filters.maxPrice);

  const updateFilter = (key, value) => {
    setFilters((filters) => ({ ...filters, [key]: value }));
  };

  const updateNumeric = (key, value) => {
    if (!isNaN(Number(value)) || (value.endsWith('.') && !isNaN(Number(value.substring(0, -1))))) {
      updateFilter(key, value);
    } else {
      updateFilter(key, null);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetMinPrice = useCallback(
    debounce((value) => {
      updateNumeric('minPrice', value);
    }, 500),
    [updateNumeric]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetMaxPrice = useCallback(
    debounce((value) => {
      updateNumeric('maxPrice', value);
    }, 500),
    [updateNumeric]
  );

  return (
    <Fragment>
      <CheckFilter
        name={config.catalog.topic.label}
        items={topics}
        selectedItems={filters.topics}
        open={true}
        setSelectedItems={(value) => updateFilter(config.catalog.topic.multi, value)}
      />
      <hr />
      <details className={priceFilterStyle} open={true}>
        <summary>
          <div className={summary}>
            Preis
            {(filters.maxPrice || filters.minPrice) && (
              <button
                className={clearButton}
                onClick={() => {
                  setMinPrice('');
                  setMaxPrice('');
                  setFilters((filters) => ({
                    ...filters,
                    maxPrice: undefined,
                    minPrice: undefined,
                  }));
                }}
              >
                Zurücksetzen
              </button>
            )}
          </div>
        </summary>
        <div className={priceFields}>
          <CurrencyField
            {...currencyCode}
            aria-label="Minimum price"
            value={minPrice}
            onChange={(e) => {
              setMinPrice(e.currentTarget.value);
              debouncedSetMinPrice(e.currentTarget.value);
            }}
          />{' '}
          –{' '}
          <CurrencyField
            {...currencyCode}
            aria-label="Maximum price"
            value={maxPrice}
            onChange={(e) => {
              setMaxPrice(e.currentTarget.value);
              debouncedSetMaxPrice(e.currentTarget.value);
            }}
          />
        </div>
      </details>
      <hr />
      <CheckFilter
        name={config.catalog.destination.label}
        items={destinations}
        selectedItems={filters.destinations}
        open={false}
        setSelectedItems={(value) => updateFilter(config.catalog.destination.multi, value)}
      />
      <hr />
      <CheckFilter
        name={config.catalog.triptype.label}
        items={triptypes}
        selectedItems={filters.triptypes}
        open={false}
        setSelectedItems={(value) => updateFilter(config.catalog.triptype.multi, value)}
      />
      <hr />
      <CheckFilter
        name={config.catalog.venue.label}
        items={venues}
        selectedItems={filters.venues}
        open={false}
        setSelectedItems={(value) => updateFilter(config.catalog.venue.multi, value)}
      />
      <hr />
      <CheckFilter
        name={config.catalog.patch.label}
        items={patches}
        selectedItems={filters.patches}
        open={false}
        setSelectedItems={(value) => updateFilter(config.catalog.patch.multi, value)}
      />
      <hr />
      <CheckFilter
        name={config.catalog.quality.label}
        items={qualities}
        selectedItems={filters.qualities}
        open={false}
        setSelectedItems={(value) => updateFilter(config.catalog.quality.multi, value)}
      />
      <hr />
      <CheckFilter
        name={config.catalog.service.label}
        items={services}
        selectedItems={filters.services}
        open={false}
        setSelectedItems={(value) => updateFilter(config.catalog.service.multi, value)}
      />
      <hr />
      <CheckFilter
        name={config.catalog.property.label}
        items={properties}
        selectedItems={filters.properties}
        open={false}
        setSelectedItems={(value) => updateFilter(config.catalog.property.multi, value)}
      />
    </Fragment>
  );
}
